//学科列表
export const SubjectTypeEnum = {
	"MATH": "数学",
	"ENGLISH": "英语",
	"CHINESE": "语文",
	"PHYSICS": "物理",
	"CHEMISTRY": "化学",
	"BIOLOGY": "生物",
	"HISTORY": "历史",
	"GEOGRAPHY": "地理",
	"POLITICS": "道法"
}

//渠道
export const ChanelEnum = {
	"DOU_DIAN": "抖店",
	"XIAO_HONG_SHU": "小红书"
}

//校区列表
export const SchoolZoneEnum = {
	"BJ": "北京校区",
	"TJ": "天津校区",
	"SY": "沈阳校区",
	"WYS": "武夷山校区",
	"SJZ": "石家庄校区",
}

export const GradeEnum = {
	"GRADE_1": "1阶",
	"GRADE_2": "2阶",
	"GRADE_3": "3阶",
	"GRADE_4": "4阶",
	"GRADE_5": "5阶",
	"GRADE_6": "6阶",
	"GRADE_7": "7阶",
	"GRADE_8": "8阶",
	"GRADE_9": "9阶",
	"GRADE_10": "10阶",
	"GRADE_11": "11阶",
	"GRADE_12": "12阶",
	"GRADE": "其它"
}

export const StageEnum = {
	"PRI": "小学",
	"MID": "初中",
	"HIG": "高中",
}

export const TermEnum = {
	"SPR": "春季",
	"SUM": "暑假",
	"FAL": "秋季",
	"WIN": "寒假",
}

export const CourseYearEnum = {
	"2024-2025学年": "2024-2025学年"
}

export const CategoryEnum = {
	"AES": "美育",
	"THI": "思维",
	"INTL": "国际",
	"SCI": "科学",
}

export const ProductTypeEnum = {
	"LITTLE_GROUP": "小组课",
	"EXERCISE": "刷题课"
}

export const OrderTypeEnum = { //订单类型
	"HONG_KONG_1V1": "香港1v1",
	"LITTLE_GROUP": "小组课",
	"EXERCISE": "刷题课"
}