export function validateUsername(rule, value, callback) {
	if (value.length < 1 || value.length > 20) {
		callback(new Error('用户名必须在1到20位的字符内！'))
	} else {
		callback()
	}

}

//正整数验证
export function validatePositiveInteger(rule, val, callback) {
	let z_reg = /^[1-9]\d*$/
	if (z_reg.test(val)) {
		callback()
	} else {
		callback(new Error('请输入正整数！'))
	}
}

//折扣验证，折扣范围 0.1~9.9，仅支持一位小数
export function validateDiscount(rule, val, callback) {
	let regDis = /^[0-9]{1}(\.[0-9])?$/  //前提条件 val>0
	if (val) { //有内容则判断
		let tmpVal = Number(val)
		if (tmpVal > 0 && tmpVal < 10 && regDis.test(tmpVal)) {
			callback()
		} else {
			callback(new Error('折扣格式错误，折扣范围 0.1~9.9，小数点后仅支持一位小数'))
		}
	} else { //无内容，则不判断
		callback()
	}

}

//邮箱验证
export function validateEmail(rule, val, callback) {
	if (val) {
		var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
		if (!reg.test(val)) {
			callback(new Error('邮箱格式错误'))
		} else {
			callback()
		}
	} else { //无内容，则不判断
		callback()
	}
}

//验证手机号
export function validateMobilePhone(rule, value, callback) {
	if (value) {
		var reg = /^1[3456789]\d{9}$/;
		if (!reg.test(value)) {
			callback(new Error('请输入有效的手机号码'))
		} else {
			callback()
		}
	} else {//无内容，则不判断
		callback()
	}

}
